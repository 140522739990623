import React from "react";
import Layout from "layout";
import { Link } from "gatsby";
import Hero from "common/hero";
import Seo from "common/seo";
import { Row, Col } from "react-grid-system";
import { TypeA } from "common/callouts";
import heroImageRef from "images/face/hero.webp";
import icon1 from "images/icons/icon-impact.webp";
import icon2 from "images/icons/icon-technology.webp";
import icon3 from "images/icons/icon-face-product.webp";
import img1 from "images/callouts/cout-face-footer-resources.webp";
import img2 from "images/callouts/cout-footer-daily-skin.webp";
import img3 from "images/callouts/sunproducts2x.webp";
//import img3 from "images/callouts/cout-footer-facecare.webp";

import "./face.scss";

function BodyPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-face"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Face"
        pageDescription="Learn about Eucerin Sun and its formulation, offering your patients beyond braod-spectrum protection"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container colored-boxes">
          <h2>New ways to help patients understand the science of facial aging</h2>
          <Row>
            <Col sm={12} md={3}>
              <ColoredBox
                imgRef={icon1}
                imgAlt="Teal icon with a piece of paper and checkmark"
                headText="Latest Thinking"
                bodyText="SKIN AGING FACTORS"
                boxLink="/face/skinagingfactors"
                headBgColor="#6ACAD5"
              />
            </Col>
            <Col sm={12} md={3}>
              <ColoredBox
                imgRef={icon2}
                imgAlt="Baby blue icon with bubbles"
                headText="TECHNOLOGY"
                bodyText="IMMERSIVE HYDRATION"
                boxLink="/face/immersivehydrationtechnology"
                headBgColor="#67C1ED"
              />
            </Col>
            <Col sm={12} md={3}>
              <ColoredBox
                imgRef={icon3}
                imgAlt="Dark blue icon with a drawing of a woman's face"
                headText="Product Family"
                bodyText="Eucerin<sup>®</sup> Face"
                boxLink="/face/eucerinfaceproducts"
                headBgColor="#036E9E"
              />
            </Col>
          </Row>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img1}
                  imgAlt="Eucerin face product family"
                  caption="Find resources to support your
                  face care recommendations"
                  buttonText="Browse resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img2}
                  imgAlt="Explore Body Care"
                  caption="Clinically proven therapeutic
                  solutions for daily skin care"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img3}
                  imgAlt="Explore Sun Care"
                  caption="Sun protection for everyone under the sun"
                  buttonText="EXPLORE SUN CARE"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}
export default BodyPage;

const MyHero = () => (
  <Hero className="inner-hero" bgImagePath={heroImageRef} altText="fading image of a womans face">
    <div className="inner-hero-content">
      <h1>
      Discover face care that goes {" "}
        <nobr>beyond hydration</nobr>
      </h1>
      <p className="hero-reg-text">
      Therapeutic goals for face care can now include premature aging strategies. Discuss with patients how simple routines can help provide smoother, more radiant skin.
      </p>
    </div>
  </Hero>
);

const ColoredBox = ({
  imgRef,
  imgAlt,
  headText,
  bodyText,
  boxLink,
  headBgColor,
}) => (
  <div className="colored-box">
    <img src={imgRef} alt={imgAlt || ""} />
    <h3 style={{ backgroundColor: headBgColor }}>{headText}</h3>
    <Link to={boxLink}>
      {" "}
      <p className="box-text" dangerouslySetInnerHTML={{ __html: bodyText }} />
    </Link>
  </div>
);
